import React, { Suspense, lazy } from 'react';

import Loading from './Loading';
import Footer from './Footer';

const Navbar = lazy(() => import('./Navbar'));
const Home = lazy(() => import('./Home'));
const About = lazy(() => import('./About'));
const Skills = lazy(() => import('./Skills'));
const Projects = lazy(() => import('./Projects'));
const Blogs = lazy(() => import('./Blogs'));
const Contact = lazy(() => import('./Contact'));

function App() {
	return (
		<Suspense fallback={<Loading />}>
			<div
				className="flex min-h-screen"
				Style={'background: rgba(255, 215, 0, 0.5)'}
			>
				<Navbar className="fixed flex-col items-center hidden min-h-screen py-8 bg-white border-gray-400 md:flex md:w-24 " />
				<div className="mx-auto md:mx-0 md:pl-32">
					<Home />
					<About />
					<Skills />
					<Projects />
					{/* <Blogs /> */}
					<Contact />
				</div>
			</div>
			<Footer />
		</Suspense>
	);
}

export default App;
