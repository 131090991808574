import React from 'react';

function Loading() {
	return (
		<div className="container flex items-center justify-center min-h-screen wrapper loading ">
			<div className="three"></div>
		</div>
	);
}

export default Loading;
